body {
  padding: 0 !important;
  margin: 0 !important;
}
.btn:hover {
  cursor: pointer !important;
  color: black;
}
.btn:active {
  text-decoration: underline;
}
.disabled,
.disabled:hover,
.disabled:focus {
  cursor: not-allowed !important;
  text-decoration: none !important;
  color: black;
}
aside {
  height: calc(100vh - 48px);
  overflow: scroll;
}
.logo {
  width: 100%;
  height: 48px;
  padding-left: 11px !important;
  padding-right: 20px;
}
.logo > h1 {
  color: #000;
  line-height: 48px;
  font-size: 1.75em;
  margin: 0 0 0 12px;
  display: inline-block;
}
header {
  font-weight: normal;
}
footer {
  text-align: center;
}
main {
  min-height: calc(100vh - 60px - 71px) !important;
  /* fill whole screen*/
  padding: 24px;
  padding-bottom: 0;
}
.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}
aside,
section,
main,
.scrollable {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: thin;
  /* Firefox */
}
aside::-webkit-scrollbar,
section::-webkit-scrollbar,
main::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.button-edit {
  color: #1677ff !important;
  border-color: #1677ff !important;
  background: #fff;
}
.button-edit:hover {
  color: #fff !important;
  background: #1677ff !important;
}
.button-add {
  color: #198754 !important;
  border-color: #198754 !important;
  background: #fff;
}
.button-add:hover {
  color: #fff !important;
  background: #198754 !important;
}
.button-delete {
  color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  background: #fff;
}
.button-delete:hover {
  color: #fff !important;
  background: #ff4d4f !important;
}
.cursor-pointer:hover {
  cursor: pointer;
}
.ant-notification {
  z-index: 9999;
}
.action-delete {
  color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  background: #fff;
}
.action-delete:hover {
  scale: 130%;
}
.ant-table-body {
  overflow-y: auto !important;
  overflow-x: auto !important;
}
.ant-table-wrapper,
.ant-table-wrapper > *,
.ant-table-wrapper > * > *,
.ant-table,
.ant-table-footer {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.reportTable tr:first-child th:first-child {
  -moz-border-radius-topleft: 8px;
  -webkit-border-top-left-radius: 8px;
  border-top-left-radius: 8px;
}
.reportTable tr:first-child th:last-child {
  -moz-border-radius-topright: 8px;
  -webkit-border-top-right-radius: 8px;
  border-top-right-radius: 8px;
}
.reportTable tr:last-child td:first-child {
  -moz-border-radius-bottomleft: 8px;
  -webkit-border-bottom-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.reportTable tr:last-child td:last-child {
  -moz-border-radius-bottomright: 8px;
  -webkit-border-bottom-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.reportTable,
.reportTable th,
.reportTable td {
  border-spacing: 0;
}
.reportTable th,
.reportTable td {
  padding-bottom: 2px;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #e8e8e8;
}
.reportTable th {
  background: #fafafa;
  text-align: left;
}
[data-slate-editor] > * + * {
  margin-top: -0.75em;
}
[report-editor] > * + * {
  margin-top: 1em;
}
.pdf-preview > .ant-modal-content > .ant-modal-body > .ant-modal-confirm-body-wrapper > .ant-modal-confirm-body > .ant-modal-confirm-content {
  height: 80vh;
  color: red;
}
